var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-0 overflow-y-auto",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticStyle: {
      "max-width": "1000px"
    }
  }, [_c('v-col', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.newStatus
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v("New Status")], 1), _c('v-switch', {
    staticClass: "float-right mt-1",
    attrs: {
      "label": "Show Descriptions"
    },
    model: {
      value: _vm.showDescriptions,
      callback: function callback($$v) {
        _vm.showDescriptions = $$v;
      },
      expression: "showDescriptions"
    }
  })], 1)], 1), _c('div', {
    ref: "sortArea",
    staticStyle: {
      "max-width": "1000px"
    }
  }, _vm._l(_vm.statuses, function (s) {
    return _c('v-card', {
      key: s.xid,
      staticClass: "sortBlock mb-4 pb-0",
      attrs: {
        "data-id": s.xid,
        "disabled": _vm.loading,
        "color": s.settings.colour
      },
      on: {
        "xclick": function xclick($event) {
          return _vm.showData(s);
        }
      }
    }, [_c('v-card-title', {
      staticClass: "white--text pa-1 dragMe"
    }, [_c('v-icon', {
      staticClass: "ma-1"
    }, [_vm._v("mdi-drag")]), _c('span', {
      staticClass: "text-button"
    }, [_vm._v(_vm._s(s.name))]), _c('v-spacer'), _c('v-btn', {
      staticClass: "mr-2",
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toggleEmail(s);
        }
      }
    }, [_c('v-icon', [_vm._v(_vm._s(s.settings.email ? "mdi-email" : "mdi-email-off-outline"))])], 1), _c('v-menu', {
      attrs: {
        "offset-y": "",
        "rounded": "xl"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v("mdi-palette")])];
        }
      }], null, true)
    }, [_c('v-list', _vm._l(_vm.colourOptions, function (c) {
      return _c('v-list-item', {
        key: c,
        attrs: {
          "link": ""
        },
        on: {
          "click": function click($event) {
            return _vm.setColor(s, c);
          }
        }
      }, [_c('v-icon', {
        attrs: {
          "color": c
        }
      }, [_vm._v("mdi-circle")])], 1);
    }), 1)], 1), _c('v-icon', {
      staticClass: "ma-1",
      attrs: {
        "disabled": Boolean(s.system)
      },
      on: {
        "click": function click($event) {
          return _vm.deleteStatus(s);
        }
      }
    }, [_vm._v("mdi-delete")])], 1), _vm.showDescriptions ? _c('v-card-text', {
      staticClass: "white pa-1 pt-2"
    }, [_c('v-textarea', {
      staticClass: "pa-1",
      attrs: {
        "placeholder": "Write a short description for your customers...",
        "rows": "1",
        "hide-details": "",
        "auto-grow": "",
        "value": s.settings.description
      },
      on: {
        "change": function change(v) {
          s.settings.description = v;
          _vm.update(s);
        }
      }
    })], 1) : _vm._e()], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }