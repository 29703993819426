<template>

  <v-container fluid class='pt-0 overflow-y-auto'>

    <v-row style='max-width:1000px;'>
      <v-col>
        <v-btn class='primary' @click='newStatus'><v-icon>mdi-plus</v-icon>New Status</v-btn>
        <v-switch class='float-right mt-1' label="Show Descriptions" v-model='showDescriptions' />
      </v-col>
    </v-row>


    <div ref='sortArea' style='max-width:1000px;' class=''>


      <v-card v-for="s in statuses" v-bind:key="s.xid"
        :data-id="s.xid"
        @xclick='showData(s)'
        class='sortBlock mb-4 pb-0'
        :disabled='loading'
        :color='s.settings.colour'
      >
        <v-card-title class='white--text pa-1 dragMe'>
          <v-icon class='ma-1'>mdi-drag</v-icon>
          <span class='text-button'>{{s.name}}</span>
          <v-spacer />
          <v-btn icon class="mr-2" @click="toggleEmail(s)"><v-icon>{{s.settings.email?"mdi-email":"mdi-email-off-outline"}}</v-icon></v-btn>
          <!--<v-icon class='ma-1' @click="showData(s)">mdi-help-circle</v-icon>-->
          <v-menu offset-y rounded='xl'>
            <template v-slot:activator='{on,attrs}'>
              <v-icon v-bind='attrs' v-on='on'>mdi-palette</v-icon>
            </template>
            <v-list>
              <v-list-item v-for='c in colourOptions' v-bind:key='c' link @click='setColor(s, c)'>
                <v-icon :color='c'>mdi-circle</v-icon>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-icon class='ma-1' @click="deleteStatus(s)" :disabled='Boolean(s.system)'>mdi-delete</v-icon>

        </v-card-title>
        <v-card-text class='white pa-1 pt-2' v-if='showDescriptions'>
          <v-textarea
            class='pa-1'
            placeholder="Write a short description for your customers..."
            rows=1
            hide-details auto-grow
            :value='s.settings.description'
            @change='v => { s.settings.description = v; update(s); }'
            />
        </v-card-text>
      </v-card>

    </div>

  </v-container>

</template>

<style lang="scss">
//@import "@/assets/common";
.sortBlock{
  .dragMe{
    cursor: move;
  }
}
</style>

<script>
import { mapActions } from 'vuex'
import Sortable from 'sortablejs';

export default {
  name: '',

  components: {
  },

  data: () => ({
    sortable: null,
    statuses: [],
    loading: false,
    currentOrder: [],
    colourOptions: ['primary', 'warning', 'error', 'info'],
    showDescriptions: false,
  }),

  computed: {
  },

  watch: {
  },

  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('UI', ['PROMPT', 'CONFIRM']),

    makeSortable(){
      this.sortable = new Sortable(this.$refs.sortArea, {
        animation: 250,
        handle: '.dragMe',
        swapThreshold: 0.5,
        onEnd: this.updateOrder,
      });
    },

    fetchStatuses(){
      this.loading = true;
      return this.API({ method: 'GET', endpoint: `jobStatus` })
      .then(statuses => {
        this.statuses.splice(0); //clear list so that they re-order
        this.$nextTick(() => { this.statuses = statuses; });
      })
      .finally(() => { this.loading = false; });
    },

    showData(status){
      alert(JSON.stringify(status));
    },

    updateOrder(e){
      if(e.oldIndex == e.newIndex) return;
      let sort_order = this.sortable.toArray();
      this.API({ method: 'POST', endpoint: `jobStatus/sort_order`, data: { sort_order } })
    },

    setColor(status, colour){
      status.settings.colour = colour;
      this.update(status);
    },

    deleteStatus(status){
      this.CONFIRM({ message: `Delete the status "${status.name}"?`, title: "Confirm Delete" })
      .then(() => {
        this.loading = true;
        this.API({ method: 'DELETE', endpoint: `jobStatus/${status.xid}` })
        .then(this.fetchStatuses)
        .finally(() => { this.loading = false; });
      });
    },

    newStatus(){
      this.PROMPT({ message: "What is the new status label?", title: "Create Status" })
      .then(label => {
        if(!label.length) return;
        this.loading = true;
        this.API({ method: 'PUT', endpoint: `jobStatus`, data: { label } })
        .then(this.fetchStatuses)
        .finally(() => { this.loading = false; });
      });
    },

    autosaveDescription(status, description){
      this.API({ method: 'PATCH', endpoint: `jobStatus/${status.xid}`, data: { description }, autosave: 5000 })
    },

    toggleEmail(status){
      status.settings.email = !status.settings.email;
      this.update(status);
    },

    update(status){
      let data = {
        description: status.settings.description,
        email: status.settings.email,
        colour: status.settings.colour
      };
      this.loading = true;
      this.API({ method: 'PATCH', endpoint: `jobStatus/${status.xid}`, data })
      .finally(() => { this.loading = false; });
    }

  },

  mounted() {
    this.makeSortable();
    this.fetchStatuses();
  },

};
</script>
